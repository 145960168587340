<template>

  <div class="row" v-if="experience">
    <div class="col-lg-8">
       <div class="ibox">
        <div class="ibox-content">
          <h1>Experience</h1>
          <div v-if="experience.offre">
            
            Offre : {{ experience.offre.titre }}<br />
            Type : {{ experience.offre.type.libelle }}
            <div v-if="experience.date">
              <h2>Date</h2>
              {{ experience.date.date }}
            </div>
            

            <h2>Participants</h2>
            <div v-for="user in experience.clients" :key="user.id">
              - {{ user.prenom }} {{ user.nom }}
            </div>

          </div>
          <div>
            <button class="btn btn-default" @click="launchVisio()">Lancer la visio conférence</button>
          </div>
          
          
        </div>
       </div>
    </div>    
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Experience",
  data() {
    return {
      experience: {},
    };
  },
  validations() {
      return {
        experience: {},
      }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {
    fetchData() {
      
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });  
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/experience/" +
            this.$route.params.uuid,
          {}
        )
        .then((resp) => {          
          loading.close();
          this.experience = resp.data;          
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    launchVisio() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/experience/visio/" +
            this.$route.params.uuid,
          {}
        )
        .then((resp) => {          
          loading.close();
          window.open(resp.data,'_blank');
        })
        .catch((error) => {
          loading.close();
          console.log("ERROR", error);
        });
    }
  }
};
</script>